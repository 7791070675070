import * as React from "react";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuBar: {
    backgroundColor: "#efefef",
    color: "#3fb58f",
  },
  menuLink: {
    textDecoration: "none",
    color: "#3fb58f",
  },
  menu: {
    [theme.breakpoints.down('sm')]: {
        marginTop: "41px",
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: "49px",
      },
    
    padding: 0,
    "& .MuiPaper-root": {
      backgroundColor: "#f7f7f7",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <AppBar position="static" className={classes.menuBar} elevation={1}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.menu}
            PaperProps={{ square: true, elevation: 2 }}
          >
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to="/" className={classes.menuLink}>
                About me
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose} className={classes.menuItem}>
              <Link to="/blog" className={classes.menuLink}>
                Blog
              </Link>
            </MenuItem>
          </Menu>
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Jake Rowsell
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
